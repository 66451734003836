import React from 'react';

import styled from '@emotion/styled';
import { spacing, mediaQuery, breakpoint } from 'styles';

import {
  Card,
  CardContent,
  Image,
  Heading,
  Subtitle,
  Description,
} from 'components';

import {
  Section,
  HeadingDescription,
} from '../Home.styles';

const CardContainer = styled.section`
  display: grid;
  grid-gap: ${spacing.row}px;

  ${breakpoint(mediaQuery.mobile)} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing.section}px;
  }

  ${breakpoint(mediaQuery.desktop)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const contents = [
  {
    image: 'https://t1.daumcdn.net/thumb/R708x0/?fname=https%3A%2F%2Ft1.daumcdn.net%2Fnews%2F201710%2F10%2Fstoryfunding%2F20171010100603258helh.jpg',
    title: '결과물이 남는 교육',
    content: '설리번 프로젝트를 들으면, 수업이 끝나고 나만의 결과물을 하나씩 가질 수 있어요. 설리번 선생님과 만들고 싶은 것을 만들어보세요.',
  },
  {
    image: 'https://t1.daumcdn.net/thumb/R708x0/?fname=https%3A%2F%2Ft1.daumcdn.net%2Fnews%2F201710%2F10%2Fstoryfunding%2F20171010100602828epoq.jpg',
    title: '이심전심, 마음이 통하는 교육',
    content: '설리번 선생님들도 처음에는 코딩을 어려워했어요. 어디서 힘들어할지, 어떻게 하면 재밌어할지, 경험과 이야기를 담아 교육을 만들어요.',
  },
  {
    image: 'https://t1.daumcdn.net/thumb/R708x0/?fname=https%3A%2F%2Ft1.daumcdn.net%2Fnews%2F201711%2F02%2Fstoryfunding%2F20171102110038038dvti.png',
    title: '도움이 필요할 때 곁에 있는 교육',
    content: '어렵거나 궁금한 게 있으면 설리번 선생님에게 연락하세요. 교육이 다 끝난 후에도 괜찮아요. 지식을 내 것으로 만드는 것에만 집중해요.',
  },
];

const HomeEducation = () => (
  <Section>
    <Heading>
      우리는 이런 교육을 해요  🎩
    </Heading>
    <HeadingDescription />
    <CardContainer>
      {contents.map(({ image, title, content }) => (
        <Card key={title}>
          <Image
            css={{
              width: '100%',
              height: '210px',
              marginBottom: `${spacing.row + spacing.leading}px`,
            }}
            src={image}
            alt={title}
          />
          <CardContent>
            <Subtitle>
              {title}
            </Subtitle>
            <Description>
              {content}
            </Description>
          </CardContent>
        </Card>
      ))}
    </CardContainer>
  </Section>
);

export default HomeEducation;