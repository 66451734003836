import React from 'react';

import { SEO } from 'components';

import Layout from 'layouts/base';
import HomeView from 'layouts/home';

const IndexPage = () => (
  <Layout>
    <SEO />
    <HomeView />
  </Layout>
);

export default IndexPage;
