import React, { useState, useCallback, useEffect } from 'react';
import {
  Link,
  useStaticQuery,
  graphql,
} from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { spacing, mediaQuery, breakpoint } from 'styles';

import { shuffle } from 'utils';

import {
  Button,
  Card,
  CardContent,
  Icon,
  Image,
  Heading,
  Subtitle,
  Body,
  Description,
} from 'components';

import {
  Section,
  HeadingDescription,
} from '../Home.styles';

const CardContainer = styled.section`
  display: grid;
  grid-gap: ${spacing.row}px;

  ${breakpoint(mediaQuery.mobile)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint(mediaQuery.tablet)} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${spacing.section}px;
  }
`;

const Row = styled.div`
  width: 100%;
  margin-top: ${spacing.section}px;
  text-align: center;

  ${Button} {
    width: 136px;
  }
`;

const imageStyle = css`
  width: 100%;
  height: 210px;
  margin-bottom: ${spacing.row + spacing.leading}px;
`;

const HomeFeatured = () => {
  const [list, setList] = useState<any[]>([]);

  const {
    allMdx: {
      edges,
    },
  } = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              coverImage
              calendar
            }
          }
        }
      }
    }
  `);

  const getStartDate = useCallback(
    (calendar: string) => (
      calendar.split(' ')
        .splice(0, 2)
        .join(' ')
    ),
    [],
  );

  useEffect(() => {
    const updatedList = shuffle(edges).slice(0, 6);
    setList(updatedList);
  }, []);

  return (
    <Section>
      <Heading>
        겨울 커리큘럼이 기다리고 있어요 👋
      </Heading>
      <HeadingDescription>
        <Body>
          이불 속에서 뒹굴거리기만 하기에는 겨울 방학이 너무 길지 않나요?&nbsp;
        <br />
          이번 겨울 방학에는 설리번 선생님과 함께 만들고 싶은 것을 만들어보세요.
      </Body>
        <Button
          as={Link}
          to='/curriculum/'
          type='primary'
          size='small'
          shape='text'
          data-gtm='click.more'
        >
          <span>
            모두 보기
           </span>
          <Icon type='arrow_forward' />
        </Button>
      </HeadingDescription>
      <CardContainer>
        {list.map(({
          node: {
            fields: {
              slug,
            },
            frontmatter: {
              title,
              coverImage,
              calendar,
            },
          },
        }) => (
          <Card
            key={slug}
            as={Link}
            to={slug}
            data-gtm='click.featured'
          >
            <Image
              css={imageStyle}
              src={coverImage}
              alt={title}
            />
            <CardContent>
              <Subtitle>
                {title}
            </Subtitle>
              <Description>
                {`${getStartDate(calendar)}부터 시작`}
              </Description>
            </CardContent>
          </Card>
        ))}
      </CardContainer>
      <Row>
        <Button
          as={Link}
          to='/curriculum/'
          type='primary'
          data-gtm='click.more'
        >
          모두 보기
        </Button>
      </Row>
    </Section>
  );
};

export default HomeFeatured;