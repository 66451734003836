import React from 'react';

import styled from '@emotion/styled';
import { mediaQuery, breakpoint } from 'styles';

import HomeHero from './home-hero';
import HomeFeatured from './home-featured';
import HomeEducation from './home-education';
import HomeTeacherList from './home-teacher-list';
import HomeRecommendation from './home-recommendation';

import FAQ from '../faq';

const Container = styled.section`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 6%;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 0 8%;
  }
`;

const HomeView = () => {
  return (
    <>
      <HomeHero />
      <Container>
        <HomeFeatured />
        <HomeEducation />
      </Container>
      <HomeRecommendation />
      <Container>
        <HomeTeacherList />
        <FAQ />
      </Container>
    </>
  );
};

export default HomeView;
