import React from 'react';

import styled from '@emotion/styled';
import { spacing, colors, breakpoint } from 'styles';

import {
  Icon,
  Body,
  Description,
} from 'components';

const MIN_WRAPPER_WIDTH = 1000;

const Wrapper = styled.section`
  width: 100%;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-snap-destination: 50% 50%;
  scroll-padding: 10px;

  ${breakpoint(MIN_WRAPPER_WIDTH)} {
    padding-left: 50%;
    overflow: hidden;
  }
`;

const RecommendContainer = styled.div`
  display: inline-flex;
  width: ${MIN_WRAPPER_WIDTH}px;
  margin-bottom: ${spacing.chapter}px;
  padding: 9px;

  ${breakpoint(MIN_WRAPPER_WIDTH)} {
    width: 1280px;
    transform: translateX(-50%);
  }
`;

const RecommendCard = styled.div`
  position: relative;
  height: 100%;
  padding: 40px 24px 24px;
  background-color: white;
  text-align: left;
  border-radius: 12px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.09), 0 -1px 3px 0 rgba(0, 0, 0, 0.04);
  scroll-snap-coordinate: 50% 50%;
  scroll-snap-align: center;

  .Icon {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 28px;
    color: ${colors.gray[30]};
  }

  &:nth-of-type(2) {
    margin: 72px ${spacing.section}px 0;
  }

  ${breakpoint(MIN_WRAPPER_WIDTH)} {
    padding-top: 60px;

    .Icon {
      font-size: 36px;
    }
  }
`;

const Recommend = styled(Body)`
  margin-bottom: ${spacing.row}px;
  padding-right: 12px;
  font-weight: 500;
  word-break: keep-all;
`;

const Information = styled(Description)`
  display: inline-block;
  vertical-align: center;

  &:first-of-type {
    margin-right: 8px;
    font-weight: 500;
  }
`;

const contents = [
  {
    name: '이현찬',
    position: '교육 수료생',
    content: '수업에서 쓰는 어려운 지식들도 이해하기 쉽도록 그림으로 그려주었고, 모르는 점이나 궁금한 점을 밤늦게도 친절하게 답장해주셨습니다.',
  },
  {
    name: '박진형',
    position: '교육 수료생',
    content: '초보자의 눈높이에서 설명하고 막히는 것을 풀 수 있도록 도와주십니다.누구나 프로덕트를 직접 만들면서 성취감을 느낄 수 있는 좋은 기회입니다.',
  },
  {
    name: '배재현',
    position: '교육 수료생',
    content: '만들고 싶은 것을 만드는 데 필요한 다양한 지식을 얻을 수 있었습니다.어려운 부분을 차근차근 알려주어 스스로 해낼 수 있도록 도와주었습니다.',
  },
];

const HomeRecommendation = () => (
  <Wrapper>
    <RecommendContainer>
      {contents.map(({ name, position, content }) => (
        <RecommendCard key={name}>
          <Icon type='format_quote' />
          <Recommend>
            {content}
          </Recommend>
          <div>
            <Information>
              {name}
            </Information>
            <Information>
              {position}
            </Information>
          </div>
        </RecommendCard>
      ))}
    </RecommendContainer>
  </Wrapper>
);

export default HomeRecommendation;
