import React, { useRef, useCallback } from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

import {
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import { Icon } from 'components';

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  min-height: 400px;
  background-color: ${colors.gray[10]};

  ${breakpoint(mediaQuery.tablet)} {
    height: 40vh;
    min-height: 450px;
    margin-bottom: ${spacing.chapter}px;
  }
`;

const HeroWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 80px 6% 0;

  ${breakpoint(mediaQuery.tablet, 'max')} {
    text-align: center;
  }

  ${breakpoint(mediaQuery.tablet)} {
    padding: 80px 8% 0;
  }
`;

const HeroTitle = styled.h1`
  font-size: 36px;
  line-height: 1.4;
  font-weight: bold;
  color: ${colors.gray[90]};

  .Icon {
    margin: -4px 8px 0;
    font-size: 22px;
    color: ${colors.gray[40]};
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    text-align: left;
    display: inline-block;
  }

  ${breakpoint(mediaQuery.tablet)} {
    font-size: 40px;

    .Icon {
      font-size: 28px;
    }
  }
`;

const blinking = keyframes`
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const Blinker = styled.span`
  display: inline-block;
  width: 4px;
  height: 33px;
  margin-top: -3px;
  margin-left: 6px;
  background-color: ${colors.gray[90]};
  animation: ${blinking} 1s ease-out infinite;

  ${breakpoint(mediaQuery.tablet)} {
    width: 5px;
    height: 42px;
    margin-top: -5px;
  }
`;

const ScrollButton = styled.button`
  position: absolute;
  bottom: -24px;
  width: 56px;
  height: 56px;
  background-color: white;
  color: ${colors.primary[50]};
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  transition: .15s all ease;
  will-change: margin, color, box-shadow;

  .Icon {
    font-size: 24px;
  }

  &:hover, &:focus {
    margin-bottom: -2px;
    color: ${colors.primary[60]};
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.12);
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    left: 6%;
  }

  ${breakpoint(mediaQuery.tablet)} {
    bottom: -36px;
    width: 72px;
    height: 72px;

    .Icon {
      font-size: 32px;
    }
  }
`;

const HomeHero = () => {
  const containerRef = useRef<HTMLElement>(null);

  const handleClickButton = useCallback(() => {
    if (containerRef && containerRef.current) {
      const { offsetHeight } = containerRef.current;

      window.scrollTo({
        top: offsetHeight + (spacing.chapter * 2),
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <HeroContainer ref={containerRef}>
      <HeroWrapper>
        <HeroTitle>
          <span>
            만들고 싶은 것을
          </span>
          <br />
          <span>
            코딩으로
          </span>
          <Icon type='keyboard_return' />
          <br />
          <span>
            만들어요
          </span>
          <Blinker />
        </HeroTitle>
        <ScrollButton onClick={handleClickButton}>
          <Icon type='arrow_downward'/>
        </ScrollButton>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default HomeHero;