import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import { shuffle } from 'utils';
import { HOST, MEMBER } from 'constants';

import {
  Button,
  Card,
  CardContent,
  Callout,
  Icon,
  Image,
  Heading,
  Body,
  Caption,
} from 'components';

import {
  Section,
  HeadingDescription,
} from '../Home.styles';

const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.section / 4}px;

  ${breakpoint(mediaQuery.tablet)} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${spacing.section / 2}px;
  }
`;

const ApplyButton = Button.withComponent('a');

const cardContainerStyle = css`
  margin: ${spacing.chapter}px 0;
`;

const imageStyle = css`
  width: 100%;
  height: 140px;
  margin-bottom: ${spacing.row + spacing.leading}px;
  border: 1px solid ${colors.gray[20]};

  ${breakpoint(mediaQuery.pablet)} {
    height: 200px;
  }
`;

const bodyStyle = css`
  line-height: 1.88;
  font-weight: 500;
  color: ${colors.gray[80]};
`;

const HomeTeacherList = () => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const updatedList = shuffle(Object.values(MEMBER)).slice(0, 4);
    setList(updatedList);
  }, []);

  return (
    <Section>
      <Heading>
        설리번 선생님과 함께해요  🎉
      </Heading>
      <HeadingDescription>
        <Body>
          설리번 프로젝트를 함께 만들어가는 설리번 선생님들입니다.&nbsp;
          <br />
          우리 같이 만들고 싶은 것을 코딩으로 만들어요.
        </Body>
        <Button
          as={Link}
          to='/team/'
          type='primary'
          size='small'
          shape='text'
          data-gtm='click.more'
        >
          <span>
            모두 보기
          </span>
          <Icon type='arrow_forward' />
        </Button>
      </HeadingDescription>
      <CardContainer
        css={cardContainerStyle}
      >
        {list.map(({
          name,
          image,
          introduce,
          githubID,
        }) => (
          <Card key={githubID}>
            <Image
              css={imageStyle}
              src={`${HOST.PROFILE}/${image}`}
              alt={name}
            />
            <CardContent>
              <Body css={bodyStyle}>
                {name}
              </Body>
              <Caption>
                {introduce}
              </Caption>
            </CardContent>
          </Card>
        ))}
      </CardContainer>
      <Callout
        title='우리 학교에 컴퓨터 동아리가 있나요?'
        content={(
          <>
            프로그래밍을 더 공부하는데 목마르지 않으셨나요? 이야기를 나누고 싶어요.
            <br />
            이야기할 수 있는 연락처를 남겨주세요!
          </>
        )}
        action={(
          <ApplyButton
            href='https://slvn.page.link/school-club-research'
            target='_blank'
            rel='noreferrer noopener'
            type='primary'
            size='small'
            css={{ width: '140px' }}
            data-gtm='conversion.newSchool'
          >
            알려주기
          </ApplyButton>
        )}
      />
    </Section>
  );
};

export default HomeTeacherList;